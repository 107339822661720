<template>
  <div class="card full-height bg-ligth">
    <div class="card-header">
      <h3 class="mb-0">{{ lista_campos != "" ? "Editar" : "Criar" }} Conta</h3>
    </div>
    <div class="card-body">
      <div class="col-md-12">
        <div class="form">
          <div class="row form-group">
            <h4 class="col-md-12">Conta:</h4>
            <div class="col-md-5">
              <label class="col-md-12">Usuarios:*</label>
              <treeselect
                :multiple="false"
                :options="lista_usuarios"
                placeholder="Selecione o Usuario..."
                v-model="form.usuario_id"
                @input="onInput"
              />
            </div>
            <div class="col-md-3">
              <label for="cpf" class="col-md-12">CPF ou CNPJ:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                id="cpf"
                v-mask="variableWithMask"
                v-model="cpf_cnpj1"
                placeholder="Digite o cpf ou Cnpj"
              />
            </div>

            <div class="col-md-3">
              <label for="tipo_conta" class="col-md-12">Tipo da Conta:*</label>
              <select
                disabled
                required=""
                v-model="form.tipo"
                class="form-control"
              >
                <option value="1">PJ</option>
                <option value="2">PF</option>
              </select>
            </div>
            <div class="row form-group col-md-12">
              <div class="col-md-3">
                <label for="saldo">Saldo:</label>
                <input
                  required=""
                  type="text"
                  class="form-control"
                  id="cpf"
                  v-mask="variableWithMask2"
                  v-model="saldo"
                  placeholder="Digite o saldo"
                />
              </div>
              <div class="col-md-3 mt-3">
                <v-file-input
                  label="Documento CPF/CNPJ:*"
                  truncate-length="15"
                ></v-file-input>
              </div>
              <div class="row form-group col-md-12">
                <div class="col-md-3 pull-left">
                  <div class="md-radio-inline">
                    <label>Status:*</label>
                    <div class="space d-flex">
                      <b-form-radio
                        :inline="true"
                        value="1"
                        v-model="form.status"
                      >
                        Ativo
                      </b-form-radio>

                      <b-form-radio
                        :inline="true"
                        value="2"
                        v-model="form.status"
                      >
                        Desativado
                      </b-form-radio>

                      <b-form-radio
                        :inline="true"
                        value="3"
                        v-model="form.status"
                      >
                        Suspenso
                      </b-form-radio>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="col-md-12 text-center">
          <v-btn
            color="primary"
            elevation="2"
            :loading="verif"
            @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
            class="btn btn-primary"
            :disabled="verif"
            >{{ lista_campos == "" ? "cria" : "edita" }}</v-btn
          >
        </div>
      </div>

      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [searchAddressMixin, fireAlert],
  data() {
    return {
      verif: false,

      form: {
        tipo: null,
        saldo: null,
        usuario_id: null,
        cpf_cnpj: null,
        status: 1,
      },
      variableWithMask: "",
      variableWithMask2: "",

      paramestado: "",
      value: "",
      loading: false,
      cpf_cnpj1: null,
      saldo: null,
    };
  },

  computed: {
    lista_usuarios() {
      let usuarios = Object.values(
        this.$store.state.configUsuarios.lista_usuarios
      );

      let data = [];
      for (let i = 0; i < usuarios.length; i++) {
        data.push({ id: usuarios[i].id, label: usuarios[i].nome_sobrenome });
      }
      return data;
    },
    mensagem_alert() {
      return this.$store.state.contas.mensagem_alert;
    },
    lista_perfil() {
      return this.$store.state.configEmpresa.lista_perfil;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
  },
  created() {
    this.preenxerCampor();
  },
  watch: {
    cpf_cnpj1: function () {
      this.variableWithMask =
        this.cpf_cnpj1.length <= 14 ? "###.###.###-##" : "##.###.###/####-##";
      this.form.cpf_cnpj = this.cpf_cnpj1.replace(/[^0-9]/g, "");

      this.form.tipo = this.cpf_cnpj1.length <= 14 ? 2 : 1;
    },
    saldo: function () {
      let pa = "#";
      for (let i = 0; i < this.saldo.length; i++) {
        if (i > 6) {
          pa = pa + "#";
        }
      }
      this.variableWithMask2 = "R$" + pa + "#,##";
      console.log(this.variableWithMask2);
      this.form.saldo = parseFloat(
        this.saldo.replace("R$", "").replace(",", ".")
      );
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Tem Certeza?",
        message: `Esta ação  ` + tipo + ` uma conta para o cliente no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("contas/create_contas", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "contas",
      });
    },
    onInput(value) {
         let usuarios = Object.values(
        this.$store.state.configUsuarios.lista_usuarios
      );
        usuarios = usuarios.filter(
            (use) => use.id === value
        );
        console.log(usuarios[0])
        this.cpf_cnpj1 = usuarios[0].cpf
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("contas/update_contas", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "contas",
      });
    },
    async preenxerCampor() {
      await this.$store.dispatch("configUsuarios/listar_usuarios_sistema");

      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          tipo: this.lista_campos.tipo,
          usuario_id: this.lista_campos.usuario_id,

          status: 1,
        };
      this.saldo = "R$" + this.lista_campos.saldo.toString().replace(".", ",");
      console.log(this.saldo);
      this.cpf_cnpj1 = this.lista_campos.cpf_cnpj;
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>