import { render, staticRenderFns } from "./createContas.vue?vue&type=template&id=75a2f8fa&scoped=true&"
import script from "./createContas.vue?vue&type=script&lang=js&"
export * from "./createContas.vue?vue&type=script&lang=js&"
import style0 from "./createContas.vue?vue&type=style&index=0&id=75a2f8fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75a2f8fa",
  null
  
)

export default component.exports